import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    badge: {
        position: 'absolute',
        top: '20px',
        right: '-20px',
        width: '100px',
        color: '#fff',
        fontSize: '12px',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        transform: 'rotate(45deg)',
        zIndex: 1,
    }
}))

export function PromoBadge() {
    const classes = useStyles()

    return (
        <Box className={classes.badge}>
            Promo
        </Box>
    )
}
import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
        })
    },

    appBarStatic: {
        border: '0px',
    },

    appBarFixed: {
        border: '0px',
        marginTop: '58px',
    },

    tabs: {
        padding: '5px 0',
        minHeight: '25px',

        '& .MuiTabs-flexContainer': {
            gap: theme.spacing() + 'px',
        },

        '& .MuiTabScrollButton-root.Mui-disabled': {
            opacity: 0.3
        }
    },

    tab: {
        minHeight: '25px',
        borderRadius: theme.shape.borderRadius + 'px',
        border: `1px solid ${theme.palette.primary.main}`,
    }
}))

function GrupoList({ onClick, grupos, activeIndex, buscandoProdutos }) {
    const classes = useStyles()
    const [grupoList, setGrupoList] = useState([])
    const [value, setValue] = useState(0)
    const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 400 })

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        setGrupoList(grupos)
    }, [grupos])

    useEffect(() => {
        if (activeIndex) {
            setValue(activeIndex)
        }
    }, [activeIndex])


    return (
        <div style={{ minHeight: '50' }}>
            {buscandoProdutos ? <SkeletonListGroups /> : (
                <AppBar
                    variant='outlined'
                    position={trigger ? 'fixed' : 'static'}
                    color='default'
                    className={clsx(classes.appBar, trigger ? classes.appBarFixed : classes.appBarStatic)} >

                    <Tabs
                        value={value}
                        scrollButtons="on"
                        textColor="primary"
                        variant="scrollable"
                        className={classes.tabs}
                        indicatorColor="primary"
                        onChange={handleChange}>
                        {grupoList.map((grupo, index) => (
                            <Tab
                                key={index}
                                color="primary"
                                className={classes.tab}
                                label={grupo.grupoProduto.descricao}
                                onClick={() => onClick(grupo.grupoProduto.id)}
                                wrapped={true} />
                        ))}
                    </Tabs>
                </AppBar>

            )}
        </div>
    )
}

function SkeletonListGroups() {
    return (
        <Skeleton variant="rect" height={40} />
    )
}

export default GrupoList
import React, { useState, useEffect } from 'react'
import { Typography, Box, makeStyles } from '@material-ui/core'
import AuthService from '../../../services/AuthService'

const useStyles = makeStyles((theme) => ({
    paperBackground: {
        backgroundColor: theme.palette.personal.paperBackground,

        '& img': {
            maxWidth: '100%',
        }
    },
}));

export function MensagemDelivery() {

    const classes = useStyles();
    const [mensagem, setMensagem] = useState("");

    useEffect(() => {
        let idEmpresa = AuthService.getProfile()?.empresa?.id

        if (idEmpresa) {
            let service = new AuthService()
            service
                .get(`/empresa/getOnly/${idEmpresa}?atributos=descricaoDelivery`)
                .then(resp => {
                    setMensagem(resp.descricaoDelivery)
                })
                .catch(err => {
                    console.error(err);
                })
        }

    }, [])

    return (
        mensagem ? (
            <Box p={1}>
                <Box display="flex"
                    p={1}
                    className={classes.paperBackground}>
                    <Typography align="center" variant="body2" color="textSecondary" component="div">
                        <div dangerouslySetInnerHTML={{ __html: mensagem }} />
                    </Typography>
                </Box>
            </Box>
        ) : null
    )
}

export default MensagemDelivery
